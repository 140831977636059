.broadcast-message-container {
  background: #faa;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  color: #555;
  gap: 5px;

  span {
    font-weight: 500;
    flex-grow: 1;
  }

  .close-icon {
    border-radius: 50%;
    padding: 3px 8px;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}