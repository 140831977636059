$title-color: rgba(202, 139, 133);
$title-selected-color: rgba(161, 111, 106);
$text-color: #666;
$link-selected-color: #111;
$link-hover-color: #222;
$primary-color: #5e84c5;
$secondary-color: #55688b;
$icon-button-color: $secondary-color;
$active-link-bg: rgba(200, 200, 200, 0.3);
$selected-bg: rgba(159, 222, 255, 0.3);
$button-hover-bg: rgba(0, 0, 0, 0.08);
$app-background: url('../public/images/female-tourists-hand-have-happy-travel-map.jpg');
$default-font-family: Roboto, Verdana, Arial;