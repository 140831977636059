@import "../variables.scss";

.app-toolbar {
  display: flex;
  background: white;
  height: 30px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  color: $text-color;
  font-weight: 500;

  &.hidden {
    display: none;
  }

  .title-div {
    display: flex;
    align-items: center;

    img {
      opacity: 50%;
    }

    .bar-title {
      font-size: 18px;
      font-weight: 500;
      color: $title-color;
      white-space: nowrap;

      &.active{
        font-weight: 500;
        text-decoration: underline;
        text-decoration-style: dotted;
        text-decoration-color: $title-color;
        text-underline-offset: 5px;
      }

      &:hover {
        color: $title-selected-color;
      }
    }

    .beta-label {
      padding: 4px 8px;
      color: #fff;
      background-color: #ff5722;
      opacity: 0.6;
      border-radius: 5px;
      font-size: 10px;
      font-weight: bold;
      transform: rotate(-15deg);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  }

  .bar-item.active{
    color: $link-selected-color;
    font-weight: 500;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: $title-color;
    text-underline-offset: 5px;
  }

  a {
    cursor: pointer;
    padding: 5px 10px;

    &:hover {
      color: $link-hover-color;
    }
  }

  .right-icon-container {
    display: flex;
    gap: 8px;

    .bar-item,
    a {
      padding: 0;
    }
  }

  .bar-item {
    display: flex;
    align-items: center;
    color: $text-color;
    gap: 3px;
    white-space: nowrap;

    svg {
      opacity: 0.8;
    }
  }
}