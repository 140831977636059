@import "../variables.scss";

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;

  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;

    .spinner {
      width: 40px;
      height: 40px;
    }
  }

  .spinner {
    width: 25px;
    height: 25px;
    color: $icon-button-color;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}