@import '../variables.scss';

.create-page-container {
  background: #f4f7f6;
  color: $text-color;
  height: calc(100vh - 250px);
  overflow: auto;

  .create-page-header {
    padding: 20px;
    text-align: center;
  }

  .first-card {
    margin-top: '30px'
  }

  .cta-card {
    text-align: center;
    padding: 20px;
  }
}