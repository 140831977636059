@import "../../variables.scss";

.login-component-container {
  display: flex;
  flex-direction: column;
  width: min(50vw, 600px);
  opacity: 1;
  background: white;
  z-index: 100;

  button {
    min-width: 300px;
  }

  .action-pane {
    display: flex;
    justify-content: center;
  }

  .login-label {
    font-weight: bold;
    color: #333;
  }

  .item-button {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .login-component-container {
    width: 90vw;
  }
}